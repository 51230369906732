<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="">
<!-- --------------------------通用的內容--------------------------   -->
    <!--  栽培操作提醒廣播設置跳窗  -->
    <div v-if="showSetNoteDialogToDB" class="fixed inset-0 flex items-center justify-center z-40 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">栽培操作提醒廣播設置</h2>
        </div>
        <div class="mb-4">
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="date">預定日期</label>
          <!-- 與定植日期相反，min設置即當日(利用:minSetTaskAndToDoDay取得)，因為當日是最小尚未發生日(正在進行) -->
          <input v-model="dateSelectForTaskAndTodo" :min="minSetTaskAndToDoDay" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" required>
        </div>
        <div>
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="diary">提醒說明</label>
          <textarea v-model="setNoteContent" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="diary" rows="5" required></textarea>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showSetNoteDialogToDB = !showSetNoteDialogToDB" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="SetNoteDialogToDBConfirmClick" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--  Loading等待跳窗  -->
    <div v-show="isLoading" wire:loading class="z-50 fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <!--  提示警告跳窗  -->
    <div v-if="showNotificationDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-2 text-xl font-semibold text-gray-800">提示訊息</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed">
            {{ formattedMessage }}
          </p>
        </div>

        <div class="flex items-center mt-3">
          <button @click="showNotificationDialog = !showNotificationDialog" class="flex-1 px-4 py-2 mt-2 mx-10 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
<!-- --------------------------Task任務設置的內容--------------------------   -->
    <div v-if="showTaskSetDialog" class="fixed inset-0 flex items-center justify-center z-40 bg-gray-700 opacity-95">
      <div class="custom-task-dialog-h overflow-auto tableScrollbar flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">栽培操作待辦任務設置</h2>
        </div>
        <div class="mb-2">
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">預定日期</label>
          <!-- 與定植日期相反，min設置即當日(利用:minSetTaskAndToDoDay取得)，因為當日是最小尚未發生日(正在進行) -->
          <input v-model="dateSelectForTaskAndTodo" :min="minSetTaskAndToDoDay" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" required>
        </div>
        <!--  tag1下拉產品批號選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">產品批號</label>
        <div class="relative inline-block text-left z-50">
          <div class="mb-2">
            <button v-on:click="task_tag1_product_codeActive = !task_tag1_product_codeActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_task_tag1_product_code_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="task_tag1_product_codeActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectProductCodeItemEvent(task_tag1_action.action_id, $event)"
                   :data-id="task_tag1_action.id"
                   v-for="(task_tag1_action, task_tag1_action_index) in task_tag1_product_code_actions" :key="task_tag1_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ task_tag1_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag1下拉產品批號選單end      -->
        <!--  tag2下拉操作大項選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作大項</label>
        <div class="relative inline-block text-left z-40">
          <div class="mb-2">
            <button v-on:click="task_tag2_operationActive = !task_tag2_operationActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_task_tag2_operation_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="task_tag2_operationActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectOperationItemEvent(task_tag2_action.action_id, $event)"
                   v-for="(task_tag2_action, task_tag2_action_index) in task_tag2_operation_actions" :key="task_tag2_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ task_tag2_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag2下拉操作大項選單end      -->
        <!--  tag3下拉操作細節選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作細節</label>
        <div class="relative inline-block text-left z-30">
          <div class="mb-2">
            <button v-on:click="task_tag3_detailActive = !task_tag3_detailActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_task_tag3_detail_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="task_tag3_detailActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectDetailItemEvent(task_tag3_action.action_id, task_tag3_action.coef_unit, $event)"
                   v-for="(task_tag3_action, task_tag3_action_index) in task_tag3_detail_actions" :key="task_tag3_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ task_tag3_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag3下拉操作細節選單end      -->
        <!--  tag4下拉操作地點選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作地點</label>
        <div class="relative inline-block text-left z-20">
          <div class="mb-2">
            <button v-on:click="task_tag4_placeActive = !task_tag4_placeActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_task_tag4_place_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="task_tag4_placeActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectPlaceItemEvent(task_tag4_action.action_id, $event)"
                   v-for="(task_tag4_action, task_tag4_action_index) in task_tag4_place_actions" :key="task_tag4_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ task_tag4_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag4下拉操作地點選單end      -->
        <!--  tag5下拉產品名稱選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">產品名稱</label>
        <div class="relative inline-block text-left z-10">
          <div class="mb-2">
            <button v-on:click="task_tag5_productActive = !task_tag5_productActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_task_tag5_product_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="task_tag5_productActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectProductItemEvent(task_tag5_action.action_id, $event)"
                   v-for="(task_tag5_action, task_tag5_action_index) in task_tag5_product_actions" :key="task_tag5_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ task_tag5_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag5下拉產品名稱選單end      -->
        <!--  數值輸入，只有數值標籤才會顯示內定0 end      -->
        <div v-show="showTaskValueInput" class="mb-2">
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">數值輸入-單位:{{valueUnit}}</label>
          <input v-model="task_value" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" required>
        </div>
        <!--  數值輸入，只有數值標籤才會顯示內定0 end      -->
        <div>
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-2" for="diary">任務說明</label>
          <textarea v-model="setNoteContent" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="diary" rows="5" required></textarea>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showTaskSetDialog = !showTaskSetDialog" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="SeTaskDialogToDBConfirmClick" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
<!-- --------------------------栽培曆的內容--------------------------   -->
    <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
      <div class="flex flex-row items-center ml-4">
        <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
          作物品項
        </label>
        <div class="relative inline-block text-left">
          <div>
            <button v-on:click="cropItemActive = !cropItemActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_cropItem_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="cropItemActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectCropItemEvent"
                   v-for="(c_option, c_option_index) in cropItems" :key="c_option_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ c_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDateSelect" class="flex flex-row items-center ml-4 z-10">
          <label for="email" class="block text-2xl font-semibold text-o_purple-100 mr-2">
            定植日期
          </label>
          <div>
            <input id="calInputDate" @input="selectDateEvent" :min="minSelectedDate" :max="maxDate" type="date" name="date" class="text-o_purple-100 block w-full rounded-md border-0 text-xl px-3 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" placeholder="you@example.com" />
          </div>
        </div>
        <div v-if="showStationSelect" class="flex flex-row items-center ml-4 z-10">
          <label id="listbox-label2" class="block text-2xl font-semibold text-o_purple-100 mr-2">
            測站
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="weatherStationActive = !weatherStationActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                    px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu2" aria-haspopup="true" aria-expanded="true">
                {{ select_weatherStations_state }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="weatherStationActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectWeatherStationEvent"
                   v-for="(w_option, w_option_index) in weatherStations" :key="w_option_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ w_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button @click="getALlComputedResultForDynamicCalendar"
                v-if="calendarSetCompleted"
                type="button"
                class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          送出
        </button>
        <button @click="calendarOptionInit"
                v-if="true"
                type="button"
                class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          初始
        </button>
      </div>
    </div>
    <div class="flex p-1 px-5 border-b">
      <label id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold mr-3">
        能計算的定植區間: <span class="font-medium ml-1">{{minSelectedDate}} 至 {{maxDate}}</span>
      </label>
      <label v-if="wasDateSelected" id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold mr-3">
        定植後天數: <span class="font-medium ml-1">{{ getDiffBetweenSelectedAndToday }}</span>
      </label>
      <button @click="taskButtonClick(), setNoteContent = '', dateSelectForTaskAndTodo = ''"
              v-if="true"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        任務
      </button>
      <button @click="noteButtonClick(), setNoteContent = '', dateSelectForTaskAndTodo = ''"
              v-if="true"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        提醒
      </button>
    </div>
    <nav aria-label="Progress" class="mx-2 mt-3 bg-white shadow-2xl rounded-2xl">
      <ol role="list" class="divide-y divide-gray-300 rounded-2xl border md:flex md:divide-y-0">
        <li v-for="(step, stepIdx) in sortedSteps" :key="stepIdx" class="relative md:flex md:flex-1">
          <div v-if="step.status === 'complete'" class="group flex w-full items-center">
            <span class="flex items-center px-6 py-4 text-sm font-medium text-o_purple-100">
              <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-o_purple-200">
                <!-- Check完成的Icon -->
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                  <path fill="#6154a5" d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"/>
                </svg>
              </span>
              <span class="ml-4 text-xl font-medium text-o_purple-200">{{ step.name }}</span>
            </span>
          </div>
          <div v-else-if="step.status === 'current'" class="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
            <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-4 border-o_purple-100">
              <span class="text-o_purple-100 text-xl font-semibold">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-xl font-medium text-o_purple-100">{{ step.name }}</span>
          </div>
          <div v-else class="group flex items-center">
          <span class="flex items-center px-6 py-4 text-sm font-medium">
            <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-4 border-gray-300">
              <span class="text-gray-500 text-xl font-semibold">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-xl font-medium text-gray-500">{{ step.name }}</span>
          </span>
          </div>
          <template v-if="stepIdx !== steps.length - 1">
            <!-- Arrow separator for lg screens and up -->
            <div class="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
              <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
    <!-- 栽培曆內容顯示區域: 包含日曆、廣播、圖表、表格   -->
    <div class="grid grid-rows-1 grid-cols-3 gap-1.5 mt-3 custom-h-inside">
      <!-- 日曆、廣播區域 -->
      <div class="grid grid-rows-5 gap-1.5 col-span-1 row-span-full">
        <div class="tableScrollbar bg-white shadow-2xl text-left mx-2 my-2 text-2xl p-4 rounded-2xl row-span-3 overflow-auto z-0">
          <h1 class="tableScrollbar text-o_purple-200 text-2xl font-semibold mb-6">栽培日曆</h1>
          <FunctionalCalendar @dayClicked="dayClickEvent"
                              :key="markedFilterFormatDates"
                              :is-date-range="false"
                              :date-format="'yyyy-mm-dd'"
                              :change-month-function="true"
                              :change-year-function="true"
                              :markedDates="markedFilterFormatDates"
                              :newCurrentDate = "getMarkedSmallestDate"
                              class="calendar multiple text-xl"
                              ref="Calendar"
                              :alwaysUseDefaultClasses="false"></FunctionalCalendar>
        </div>
        <div class="tableScrollbar bg-white shadow-2xl rounded-2xl mx-2 my-2 row-span-2 overflow-auto p-4 z-0">
          <h1 class="text-o_purple-200 text-2xl font-semibold mb-3">栽培操作</h1>
          <!--          <h1 v-show="detailActive" class="text-o_purple-200 text-4xl font-semibold">-->
          <!--            {{ nodeName }}-->
          <!--          </h1>-->
          <!--          <p v-show="detailActive" class="text-o_purple-100 text-3xl my-3">-->
          <!--            {{ node_info_current }}-->
          <!--          </p>-->
          <ul v-show="showActionSuggestions" class="text-o_purple-100 text-3xl my-3">
            <template v-if="typeof clickedDayActionsDetail !== 'undefined' && clickedDayActionsDetail.length > 0">
              <li v-for="(action, i) in clickedDayActionsDetail" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
                <h1 class="text-right">
                  <span v-if="action.is_past" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    已發生
                  </span>
                  <span v-if="!action.is_past" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    待執行
                  </span>
                  <!--                <span v-if="record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">-->
                  <!--                    已註銷-->
                  <!--                  </span>-->
                  <!--                <span v-if="!record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">-->
                  <!--                    未註銷-->
                  <!--                  </span>-->
                </h1>
                <!-- { day_at: 1, is_past: true, action: 'doing something' }/ -->
                <span class="font-semibold text-2xl">日期: </span><span class="pb-3.5 text-2xl">{{ clickedDate }}</span><br>
                <span class="font-semibold text-2xl">定植日期: </span><span class="pb-3.5 text-2xl">{{ selectedDay }}</span><br>
                <span class="font-semibold text-2xl">執行天數: </span><span class="pb-3.5 text-2xl">{{ betweenSelectedDayAndClickedDay }}天</span><br>
                <span class="font-semibold text-2xl">預定執行日:</span><span class="pb-3.5 text-2xl">第 {{ action.day_at }}日</span><br>
                <span class="font-semibold text-2xl">執行與預定差(遲): </span><span class="pb-3.5 text-2xl">{{ betweenSelectedDayAndClickedDay - action.day_at }}天</span><br>
                <span class="font-semibold text-2xl">執行操作:</span><br>
                <div class="text-2xl">{{ action.action }}</div>
                <div class="text-right pb-3.5 ">
                  <button @click="taskButtonClick(), setNoteContent = action.action"
                          v-if="!action.is_past"
                          type="button"
                          class=" ml-3 font-semibold
                                  inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                                  shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                                  focus:ring-offset-2 focus:ring-o_purple-100">
                    任務
                  </button>
                  <button @click="noteButtonClick(), setNoteContent = action.action"
                          v-if="!action.is_past"
                          type="button"
                          class=" ml-3 font-semibold
                                  inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                                  shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                                  focus:ring-offset-2 focus:ring-o_purple-100">
                    提醒
                  </button>
                </div>
              </li>
            </template>
            <template v-else >
              <li class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-t_dark_brown">
                <h1 class="text-right">
                  <span class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    無事件
                  </span>
                </h1>
                <span class="font-semibold text-2xl">日期: </span><span class="pb-3.5 text-2xl">{{ clickedDate }}</span><br>
                <span class="font-semibold text-2xl">定植日期: </span><span class="pb-3.5 text-2xl">{{ selectedDay }}</span><br>
                <span class="font-semibold text-2xl">執行天數: </span><span class="pb-3.5 text-2xl">{{ betweenSelectedDayAndClickedDay }}天</span><br>
                <span class="font-semibold text-2xl">預定執行日:</span><span class="pb-3.5 text-2xl">無</span><br>
                <span class="font-semibold text-2xl">執行與預定差(遲): </span><span class="pb-3.5 text-2xl">無</span><br>
                <span class="font-semibold text-2xl">此日未達任何操作積溫值</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <!-- 圖表、表格區域 -->
      <div class="col-span-2 row-span-full">
        <div class="tableScrollbar rounded-2xl bg-white shadow-2xl mx-2 my-2 custom-line-chart-h-inside overflow-auto p-4">
          <h1 class="text-o_purple-200 text-2xl font-semibold mb-1">栽培圖表</h1>
          <div v-show="showChart" id="lineChart" ref="lineChartCanva" class="flex justify-center"></div>
          <!--        <div class="tableScrollbar bg-blue-60 flex justify-center">Table 預定區</div>-->
        </div>
        <div class="tableScrollbar rounded-2xl bg-white shadow-2xl mx-2 mt-5 mb-2 custom-table-h-inside overflow-auto p-4">
          <h1 class="text-o_purple-200 text-2xl font-semibold mb-1">栽培表格</h1>
          <div>
            <div v-show="showCalendarTable" class="py-2 align-middle">
              <table class="divide-y divide-gray-300 border-b-2  border-r-2 border-divide-gray-300">
                <thead class="bg-o_purple-100 ">
                  <tr class="divide-x divide-gray-300 ">
                    <th scope="col" class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white ">生育時期</th>
                    <th v-for="(stepsV, stepsI) in sortedSteps"  :key="stepsI" scope="col" class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white ">{{stepsV.name}}</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-300 bg-white bg-white">
                  <!-- day_count -->
                  <tr class="divide-x divide-gray-300">
                    <td class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white bg-o_purple-100">{{ day_count[0] }}</td>
                    <td v-for="(count, index) in day_count.slice(1)" :key="index"  class="whitespace-nowrap p-4 text-sm text-o_purple-200 text-center">{{ count }}</td>
                  </tr>
                  <!-- day_range -->
                  <tr class="divide-x divide-gray-300">
                    <td class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white bg-o_purple-100">{{ day_range[0] }}</td>
                    <td v-for="(count, index) in day_range.slice(1)" :key="index"  class="whitespace-nowrap p-4 text-sm text-o_purple-200 text-center">{{ count }}</td>
                  </tr>
                  <!-- expected_day_count -->
                  <tr class="divide-x divide-gray-300">
                    <td class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white bg-o_purple-100">{{ expected_day_count[0] }}</td>
                    <td v-for="(count, index) in expected_day_count.slice(1)" :key="index"  class="whitespace-nowrap p-4 text-sm text-o_purple-200 text-center">{{ count }}</td>
                  </tr>
                  <!-- expected_day_range -->
                  <tr class="divide-x divide-gray-300">
                    <td class="whitespace-nowrap px-4 py-3.5 text-left text-sm font-semibold text-white bg-o_purple-100">{{ expected_day_range[0] }}</td>
                    <td v-for="(count, index) in expected_day_range.slice(1)" :key="index"  class="whitespace-nowrap p-4 text-sm text-o_purple-200 text-center">{{ count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- --------------------------知識圖譜的內容--------------------------   -->
    <div v-show="switchGraphTable">
      <div class="sticky top-0 bg-gray-50 border-b border-t pb-1.5 mt-3">
        <div class="flex pt-3">
          <div class="flex flex-row items-center ml-4">
            <label id="listbox-label1" class="block text-2xl text-o_purple-100 font-semibold mr-2">
              產品
            </label>
            <div class="relative inline-block text-left">
              <div>
                <button v-on:click="isActive1 = !isActive1" type="button"
                        class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                  {{ select_state1 }}
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                  <a @click.prevent="selectProductEvent"
                     v-for="product in productList" :key="product"
                     href="#" class="block px-4 py-2 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ product }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="searchBar2" class="flex flex-row items-center ml-4">
            <label id="listbox-label2" class="block text-2xl font-semibold text-o_purple-100 mr-2">
              農場批號
            </label>
            <div class="relative inline-block text-left">
              <div>
                <button v-on:click="isActive2 = !isActive2" type="button"
                        class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                    px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                        id="options-menu2" aria-haspopup="true" aria-expanded="true">
                  {{ select_state2 }}
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg class="-mr-1 ml-2 h-5 w-5"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>

              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="selectEvent2"
                     v-for="productCode in productCodeList" :key="productCode"
                     href="#" class="block px-4 py-2 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ productCode }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button @click="clear"
                  v-if="clearActive"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            清除
          </button>
          <button @click="MaskGraph(nodeName)"
                  v-if="node_selected"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            遮蔽
          </button>
          <button @click="UpLevel(nodeName)"
                  v-if="node_selected"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            往前
          </button>
          <button @click="DownLevel(nodeName)"
                  v-if="node_selected"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            往後
          </button>
          <button @click="BackAllGraph"
                  v-if="node_selected"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            返回
          </button>
          <button @click="switchGraphTable=false"
                  v-if="true"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            表格
          </button>
          <!--        <button @click="hideNodeTest"-->
          <!--                v-if="false"-->
          <!--                type="button"-->
          <!--                class=" ml-3 font-semibold-->
          <!--              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
          <!--              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
          <!--              focus:ring-offset-2 focus:ring-o_purple-100">-->
          <!--          hide-->
          <!--        </button>-->
        </div>
      </div>
      <div class="grid grid-cols-8 grid-rows-6 gap-1.5 mt-3 custom-h-inside">
        <!-- node info     -->
        <div class="grid grid-rows-2 col-span-3 row-span-full gap-1.5">
          <div class="tableScrollbar rounded-2xl bg-white shadow-2xl col-span-full row-span-1 overflow-auto mx-2 mt-2 p-4">
            <h1 class="text-o_purple-200 text-2xl font-semibold mb-1">節點解說</h1>
            <h1 v-show="detailActive" class="text-o_purple-200 text-3xl font-semibold">
              {{ nodeName }}
            </h1>
            <div v-show="detailTermActive" class="text-o_purple-100 text-3xl my-3">
              <span class="font-semibold">詞彙多國翻譯(英/中/日):</span><br>
              {{ node_info_current.chinese }}/ {{ node_info_current.english }}/ {{ node_info_current.japanese }}
            </div>
            <ul v-show="detailActive" class="text-o_purple-100 text-3xl">
              <li>
                <span class="font-semibold">內容:</span><br>
                {{ node_info_current.content }}
              </li>
            </ul>
          </div>
          <div class="tableScrollbar bg-white shadow-2xl rounded-2xl p-4 mx-2 my-2 rows-span-1 overflow-auto">
            <h1 class="text-o_purple-20n 0 text-2xl font-semibold mb-1">操作紀錄</h1>
            <h1 v-show="recordActive" class="text-o_purple-200 text-3xl font-semibold">
              {{ nodeName }}
            </h1>
            <ul v-show="recordActive" class="text-o_purple-100 text-3xl my-3">
              <li v-for="(record, i) in records" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
                <h1 class="text-right">
                  <span v-if="record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                  <span v-if="!record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                  <span v-if="record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                  <span v-if="!record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
                </h1>
                <span class="font-semibold">產品批號:</span><br>
                <div class="pb-3.5">{{ record.product_code }}</div>
                <span class="font-semibold">操作細節:</span><br>
                <div class="pb-3.5">{{ record.detail }}</div>
                <span class="font-semibold">時間:</span><br>
                <div class="pb-3.5">{{ record.time }}</div>
                <span class="font-semibold">地點:</span><br>
                <div class="pb-3.5">{{ record.place }}</div>
              </li>
            </ul>
          </div>
        </div>

        <!--  graph canvas   -->
        <div id="graph" class="tableScrollbar rounded-2xl bg-gray-400 shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-4" ref="canvas">
          <h1 class="text-o_purple-200 text-2xl font-semibold mb-1">知識圖譜</h1>
        </div>
        <!--      <div v-if="false">暫定之後operationlist area</div>-->
      </div>
    </div>
<!-- --------------------------履歷表格的內容--------------------------   -->
    <div v-show="!switchGraphTable">
      <div class="sticky top-0 bg-gray-50 border-b border-t pb-1.5 mt-3 z-20">
        <div class="flex pt-3">
          <div class="flex flex-row items-center ml-4">
            <label id="listbox-label1" class="block text-2xl text-o_purple-100 font-semibold mr-2">
              產品
            </label>
            <div class="relative inline-block text-left">
              <div>
                <button v-on:click="tableSelectOptionActive = !tableSelectOptionActive" type="button"
                        class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                  {{ tableSelectState }}
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="tableSelectOptionActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                  <a @click.prevent="getDetailRecordByProductWithAuth"
                     v-for="tableProduct in tableProductList" :key="tableProduct"
                     href="#" class="block px-4 py-2 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ tableProduct }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            @click = "sortSwitch(1)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            序號排序
          </button>
          <button
            @click = "sortSwitch(2)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            日期排序
          </button>
          <button @click="switchGraphTable=true"
                  v-if="true"
                  type="button"
                  class=" ml-3 font-semibold
              inline-flex items-center px-3 py-0.5 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
            圖譜
          </button>
          <!--        <button @click="hideNodeTest"-->
          <!--                v-if="false"-->
          <!--                type="button"-->
          <!--                class=" ml-3 font-semibold-->
          <!--              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
          <!--              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
          <!--              focus:ring-offset-2 focus:ring-o_purple-100">-->
          <!--          hide-->
          <!--        </button>-->
        </div>
      </div>
      <div class="grid grid-cols-8 grid-rows-6 gap-1.5 mt-3 custom-record-table-inside">
        <!--  record table   -->
        <div id="table" class="tableScrollbar rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-8 row-span-full overflow-auto p-4">
          <h1 class="text-o_purple-200 text-2xl font-semibold mb-1">履歷表格</h1>
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-o_purple-100 sticky top-0 z-10">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Id
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                uuid
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                時間
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                地點
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                操作
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                細節
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                產品
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                產品批號
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                值
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                單位
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                面積
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                面積單位
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                驗證
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                註銷
              </th>
              <th scope="col" class="relative px-4 py-3">
                <span class="sr-only">註銷</span>
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="content in contentsFilterAndSortedContentsByUnixDate" :key="content.record_id">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.record_id }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.uuid }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.time }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.place }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.operation }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.detail }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.product }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.product_code }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.value }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.unit }}&emsp;&emsp;
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ content.area }}&emsp;&emsp;
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <!--  若空值則用\u2003佔位，形同&emsp(因為vuejs會自動轉義，得用代號\u2003  -->
                {{ content.area_unit ? content.area_unit : '\u2003\u2003\u2003' }}&emsp;
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.verified" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.error" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
                <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--      <div v-if="false">暫定之後operationlist area</div>-->
      </div>
    </div>
<!--    <iframe src="https://www.test-traceability.ntu.asia/#/" class="mx-2 my-2 w-full custom-h-inside rounded-2xl tableScrollbar overflow-auto"></iframe>-->
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { FunctionalCalendar } from 'vue-functional-calendar'
import UserService from '../services/user.service'
import Config from '@/config/config'
import G6 from '@antv/g6'
let graphInstance = {}
export default {
  components: { FunctionalCalendar },
  name: 'Platform',
  data () {
    return {
      isLoading: false,
      // 待辦任務、提醒廣播共用日期選擇--------
      dateSelectForTaskAndTodo: '',
      // 待辦任務用-----------------
      showTaskValueInput: false,
      valueUnit: '',
      showTaskSetDialog: false,
      select_task_tag1_product_code_actionid: '',
      select_task_tag2_operation_actionid: '',
      select_task_tag3_detail_actionid: '',
      select_task_tag4_place_actionid: '',
      select_task_tag5_product_actionid: '',
      task_action_set: [],
      task_value: 0,
      task_tag1_product_code_actions: [],
      task_tag2_operation_actions: [],
      task_tag3_detail_actions: [],
      task_tag4_place_actions: [],
      task_tag5_product_actions: [],
      // 待辦任務tag1-產品批號相關product_code
      select_task_tag1_product_code_state: '請選擇',
      task_tag1_product_codeActive: false,
      task_tag1_product_codeSelected: false,
      // 待辦任務tag2-產品操作大項相關operation
      select_task_tag2_operation_state: '請選擇',
      task_tag2_operationActive: false,
      task_tag2_operationSelected: false,
      // 待辦任務tag3-產品操作細節相關detail
      select_task_tag3_detail_state: '請選擇',
      task_tag3_detailActive: false,
      task_tag3_detailSelected: false,
      // 待辦任務tag4-產品操作地點相關place
      select_task_tag4_place_state: '請選擇',
      task_tag4_placeActive: false,
      task_tag4_placeSelected: false,
      // 待辦任務tag5-產品名稱相關product
      select_task_tag5_product_state: '請選擇',
      task_tag5_productActive: false,
      task_tag5_productSelected: false,
      // 栽培圖譜通用----------------
      optionGetCount: 0, // 用來確認初始的栽培 and 圖譜初始的內容是否皆取得，若為2表示皆取得(1表示有缺、0表示皆無)
      // 栽培曆相關------------------
      // 作物品項
      cropItems: [], // 要配合後端: (已完成
      cropItemActive: false,
      select_cropItem_state: '請選擇',
      wasCropItemSelected: false,
      // 日期
      selectedDay: '2023-05-29',
      showDateSelect: false,
      dateSelected: false,
      wasDateSelected: false,
      // 日期相關限制
      maxSelectedDate: '', // 當日減一，至少一天才能畫線有區間(台北日期)
      minSelectedDate: Config.minSelectedDate, // 當日回推年分(依據設定檔案，此設置為2年- 台北日期)
      // 測站
      weatherStationActive: false,
      showStationSelect: false,
      wasWeatherStationSelected: false,
      weatherStations: [], // 要配合後端: (已完成
      select_weatherStations_state: '請選擇',
      // 提示警告視窗
      showNotificationDialog: true,
      // 提示的訊息內容
      formattedMessage: '',
      // 所在時期相關
      steps: [
        { id: 1, name: '尚未選擇計算', href: '#', status: 'complete' },
        { id: 2, name: '   ', href: '#', status: 'upcoming' },
        { id: 3, name: '   ', href: '#', status: 'upcoming' },
        { id: 4, name: '   ', href: '#', status: 'upcoming' },
        { id: 5, name: '   ', href: '#', status: 'upcoming' }
      ], // 要配合後端: 1
      // 圖表相關
      showChart: false,
      annotationXAxisData: { 孕育期: '2023-06-07', 秧苗期: '2023-06-19', 分孽始期: '2023-08-06', 孕穗始期: '2023-09-06', 抽穗期: '2023-10-06' }, // 要配合後端: 2
      expectedData: [
        ['2023-06-07', 0],
        ['2023-06-19', 116],
        ['2023-08-06', 614],
        ['2023-09-06', 1067],
        ['2023-10-06', 1583]
      ], // 要配合後端: 3
      realData: [
        ['2023-06-07', 0],
        ['2023-06-08', 5],
        ['2023-06-09', 8],
        ['2023-06-10', 15],
        ['2023-06-10', 20],
        ['2023-06-11', 22],
        ['2023-06-12', 24],
        ['2023-06-13', 27],
        ['2023-06-14', 30],
        ['2023-06-15', 37],
        ['2023-06-20', 360]
      ], // 要配合後端: 4
      // 日曆相關
      currentDate: '',
      markedDates: {}, // 要配合後端: 5
      showActionSuggestions: false,
      // 日曆被點擊
      clickedDate: '',
      showSetNoteDialogToDB: false,
      notificationMessage: 'GH1 - 上傳格式有誤',
      setNoteContent: '', // 提醒設置內容
      // 栽培曆表格相關
      showCalendarTable: false,
      day_count: ['生育日數', '0-11', '12-59', '60-90', '91-100'], // 要配合後端: 6
      day_range: [
        '生育日期',
        '2023-05-17-2023-05-28',
        '2023-05-29-2023-07-15',
        '2023-07-16-2023-08-15',
        '2023-08-15-2023-09-25'
      ], // 要配合後端: 7
      expected_day_count: ['預期生育日數', '0-6', '7-54', '55-85', '86-90'], // 要配合後端: 8
      expected_day_range: [
        '預期生育日期',
        '2023-05-17-2023-05-23',
        '2023-05-24-2023-07-10',
        '2023-07-11-2023-08-10',
        '2023-08-10-2023-08-20'
      ], // 要配合後端: 9
      // 知識圖譜-履歷圖表切換狀態--------
      // true: 知識圖譜、false: 履歷表格
      switchGraphTable: true,
      // 履歷表格相關
      contents: [],
      // record排序種類: 1為record id排序、2為unix date排序，從bufferDB取來的original狀態是unix date排序
      sortType: 2,
      decrementOrIncrement: true,
      tableSelectOptionActive: false,
      tableSelectState: '請選擇',
      tableProductList: [],
      // 知識圖相關------------------
      isActive1: false,
      isActive2: false,
      select_state1: '請選擇',
      select_state2: '請選擇',
      productList: [],
      productCodeList: [],
      searchBar2: false,
      clearActive: false,
      nodeActive: false,
      nodeName: '',
      node_selected: false,
      detailActive: false,
      recordActive: false,
      detailTermActive: false,
      node_infos: {},
      node_info_current: {},
      node_term_current: {},
      current_graph_data: {},
      mask_graph: {},
      records: {}
    }
  },
  methods: {
    sortSwitch (dateOrID) {
      if (dateOrID === 1) {
        this.sortType = 1
        this.decrementOrIncrement = !this.decrementOrIncrement
      } else if (dateOrID === 2) {
        this.sortType = 2
        this.decrementOrIncrement = !this.decrementOrIncrement
      }
    },
    async getDetailRecordByProductWithAuth (event) {
      const product = event.target.innerText
      // 下面這個是點擊縮、放選單
      this.tableSelectOptionActive = !this.tableSelectOptionActive
      // 選後把把選擇狀態顯示出來
      this.tableSelectState = event.target.innerText
      this.isLoading = true
      await UserService.getDetailRecordByProductWithAuth(product).then(
        res => {
          const result = res.data
          if (result.is_err) {
            this.isLoading = false
            this.setNotificationDialog('無法取得履歷紀錄，若無法排除將下列錯誤代號回報: ' + result.err)
          } else {
            this.contents = result.result
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄請求成功，但無法取得服務回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄請求服務無法建立')
          return false
        }
      })
    },
    async SeTaskDialogToDBConfirmClick () {
      if (!(this.task_tag1_product_codeSelected && this.task_tag2_operationSelected && this.task_tag3_detailSelected && this.task_tag4_placeSelected && this.task_tag5_productSelected)) {
        this.setNotificationDialog('有尚未選擇標籤(顯示:請選擇)')
      } else {
        this.showTaskSetDialog = !this.showTaskSetDialog
        this.isLoading = true
        this.task_action_set = []
        this.task_action_set.push(this.select_task_tag1_product_code_actionid)
        this.task_action_set.push(this.select_task_tag2_operation_actionid)
        this.task_action_set.push(this.select_task_tag3_detail_actionid)
        this.task_action_set.push(this.select_task_tag4_place_actionid)
        this.task_action_set.push(this.select_task_tag5_product_actionid)
        this.setNotificationDialog(this.task_action_set)
        await UserService.sendTaskInfoToAppBackendToBuildTask(this.dateSelectForTaskAndTodo, this.dynamicSetNoteContent, this.task_action_set, this.task_value).then(
          res => {
            const sendTaskResult = res.data
            if (sendTaskResult.is_err) {
              // 這個的待辦任務後端不論錯誤與否都是回傳http statusOK 200，前端因此可以解讀到錯誤訊息
              this.isLoading = false
              this.setNotificationDialog('無法建立待辦任務，可檢查設置日期是否為當日或未來，若無法排除將下列錯誤代號回報: ' + sendTaskResult.err)
            } else {
              this.isLoading = false
              this.setNotificationDialog('成功設置待辦任務，可於App中看到此提醒設置，task id為: ' + sendTaskResult.result)
            }
          }
        ).catch(err => {
          if (err.response) {
            // Request made and server responded 請求成立且回應有回來，是錯誤情況
            // 若沒有要鎖住畫面則可以註解或清除掉
            this.isLoading = false
            this.setNotificationDialog('無法建立待辦任務，收到錯誤之回應，可檢查設置日期是否為當日或未來')
            return false
          } else if (err.request) {
            // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
            this.isLoading = false
            this.setNotificationDialog('無法建立待辦任務，無法取得服務回應，可檢查設置日期是否為當日或未來')
            return false
          } else {
            // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
            this.isLoading = false
            this.setNotificationDialog('無法建立待辦任務，服務請求無法建立，可檢查設置日期是否為當日或未來')
            return false
          }
        })
      }
    },
    taskButtonClick () {
      // 進行初始化
      // 每次進來要先遮蔽數值輸入，並且taskValue設為default 0
      this.showTaskValueInput = false
      this.task_value = 0
      this.valueUnit = ''
      // 初始化要先設置成等於點日曆的日期
      this.dateSelectForTaskAndTodo = this.clickedDate
      // 待辦任務tag1-產品批號相關product_code
      this.select_task_tag1_product_code_state = '請選擇'
      this.task_tag1_product_codeActive = false
      this.task_tag1_product_codeSelected = false
      // 待辦任務tag2-產品操作大項相關operation
      this.select_task_tag2_operation_state = '請選擇'
      this.task_tag2_operationActive = false
      this.task_tag2_operationSelected = false
      // 待辦任務tag3-產品操作細節相關detail
      this.select_task_tag3_detail_state = '請選擇'
      this.task_tag3_detailActive = false
      this.task_tag3_detailSelected = false
      // 待辦任務tag4-產品操作地點相關place
      this.select_task_tag4_place_state = '請選擇'
      this.task_tag4_placeActive = false
      this.task_tag4_placeSelected = false
      // 待辦任務tag5-產品名稱相關product
      this.select_task_tag5_product_state = '請選擇'
      this.task_tag5_productActive = false
      this.task_tag5_productSelected = false
      // 選擇id、set初始化
      this.select_task_tag1_product_code_actionid = ''
      this.select_task_tag2_operation_actionid = ''
      this.select_task_tag3_detail_actionid = ''
      this.select_task_tag4_place_actionid = ''
      this.select_task_tag5_product_actionid = ''
      this.task_action_set = []
      // 初始化後打開設置任務跳窗
      this.showTaskSetDialog = true
      // 後發生的跳窗若z層級一樣，會在上，不過這個z-50大於任務跳窗的z-40，所以在上
      this.setNotificationDialog('預定日期(當日/未來)與任務說明皆可再進行選擇與改寫')
    },
    noteButtonClick () {
      // 初始化要先設置成等於點日曆的日期
      this.dateSelectForTaskAndTodo = this.clickedDate
      this.showSetNoteDialogToDB = true
      // 後發生的跳窗若z層級一樣，會在上，不過這個z-50大於提醒跳窗的z-40，所以在上
      this.setNotificationDialog('預定日期(當日/未來)與提醒說明皆可再進行選擇與改寫')
    },
    async SetNoteDialogToDBConfirmClick () {
      this.showSetNoteDialogToDB = !this.showSetNoteDialogToDB
      this.isLoading = true
      await UserService.setNotificationBroadcast(this.dateSelectForTaskAndTodo, this.dynamicSetNoteContent).then(
        res => {
          const setNotificationBroadcastResult = res.data
          if (setNotificationBroadcastResult.is_err) {
            // 瀏覽器無法對end-user給予完整錯誤訊息內容，因此這個條件其實不太可能會成立
            this.isLoading = false
            this.setNotificationDialog('無法建立栽培提醒廣播，可檢查設置日期是否為當日或未來，若無法排除將下列錯誤代號回報: ' + setNotificationBroadcastResult.err)
          } else {
            this.isLoading = false
            this.setNotificationDialog('成功設置栽培提醒廣播，可於App中看到此提醒設置')
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('無法建立栽培提醒廣播，收到錯誤之回應，可檢查設置日期是否為當日或未來')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('無法建立栽培提醒廣播，無法取得服務回應，可檢查設置日期是否為當日或未來')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('無法建立栽培提醒廣播，服務請求無法建立，可檢查設置日期是否為當日或未來')
          return false
        }
      })
    },
    // 反應後端訊息
    setNotificationDialog (content) {
      this.showNotificationDialog = true
      this.formattedMessage = content
    },
    // 選擇、頁面恢復初始化
    calendarOptionInit () {
      // 所在時期回復成初始內容
      this.steps = [
        { id: 1, name: '尚未選擇計算', href: '#', status: 'complete' },
        { id: 2, name: '   ', href: '#', status: 'upcoming' },
        { id: 3, name: '   ', href: '#', status: 'upcoming' },
        { id: 4, name: '   ', href: '#', status: 'upcoming' },
        { id: 5, name: '   ', href: '#', status: 'upcoming' }
      ]
      // 栽培表格遮蔽
      this.showCalendarTable = false
      // 清理標記日期清單for calendar
      // 要先關閉顯示操作內容顯示，才this.markedDates = {}，否則會觸發computed
      this.markedDates = {}
      this.dateSelectForTaskAndTodo = ''
      // 清除line chart建立用的options {}，渲染成空白-------------------------------
      this.clickedDate = ''
      this.currentLineChartdata.destroy()
      // this.currentLineChartdata = new ApexCharts(document.querySelector('#newChartDiv'), {})// 建立新的圖表實例
      // this.currentLineChartdata.render() // 渲染新的圖表
      // -----------------------------------------------------------------------
      this.showActionSuggestions = false
      this.select_cropItem_state = '請選擇'
      this.select_weatherStations_state = '請選擇'
      this.showDateSelect = false
      this.showStationSelect = false
      const calInputDate = document.getElementById('calInputDate')
      calInputDate.value = ''
      // 各選單都恢復成未選擇狀態，這樣就不會有被之前殘留的選擇給影響
      this.wasCropItemSelected = false
      this.wasWeatherStationSelected = false
      this.wasDateSelected = false
    },
    // 測站選擇事件
    selectWeatherStationEvent (event) {
      this.select_weatherStations_state = event.target.innerText
      this.weatherStationActive = !this.weatherStationActive
      this.wasWeatherStationSelected = true
    },
    // 日期選擇事件
    selectDateEvent (event) {
      // 獲取使用者選擇的日期
      const selectedDate = event.target.value
      // 儲存選擇的日期到 selectedDay 中
      // console.log('check selected date: ', selectedDate)
      this.selectedDay = selectedDate
      this.dateSelected = !this.dateSelected
      this.showStationSelect = true
      this.wasDateSelected = true
    },
    // 作物品項選單選擇事件
    selectCropItemEvent (event) {
      this.select_cropItem_state = event.target.innerText
      this.cropItemActive = !this.cropItemActive
      this.showDateSelect = true
      this.wasCropItemSelected = true
    },
    // 任務設置之tag1: 產品批號選擇事件
    selectProductCodeItemEvent (id, event) {
      // console.log(id)
      this.select_task_tag1_product_code_actionid = id
      this.select_task_tag1_product_code_state = event.target.innerText
      this.task_tag1_product_codeActive = !this.task_tag1_product_codeActive
      this.task_tag1_product_codeSelected = true
    },
    // 任務設置之tag2: 操作大項選擇事件
    selectOperationItemEvent (id, event) {
      this.select_task_tag2_operation_actionid = id
      this.select_task_tag2_operation_state = event.target.innerText
      this.task_tag2_operationActive = !this.task_tag2_operationActive
      this.task_tag2_operationSelected = true
    },
    // 任務設置之tag3: 操作細節選擇事件
    selectDetailItemEvent (id, coefUnit, event) {
      this.select_task_tag3_detail_actionid = id
      // console.log(coefUnit)
      if (coefUnit !== '') {
        this.valueUnit = coefUnit
        const valueMsg = '為數值輸入標籤，單位為:' + coefUnit + '，沒設置內定為0'
        this.setNotificationDialog(valueMsg)
        this.showTaskValueInput = true
      } else {
        // 若否要初始化，因為可能重新選別的選項(那選項可能非數值標籤)
        this.valueUnit = ''
        this.task_value = 0
        this.showTaskValueInput = false
      }
      this.select_task_tag3_detail_state = event.target.innerText
      this.task_tag3_detailActive = !this.task_tag3_detailActive
      this.task_tag3_detailSelected = true
    },
    // 任務設置之tag4: 操作地點選擇事件
    selectPlaceItemEvent (id, event) {
      this.select_task_tag4_place_actionid = id
      this.select_task_tag4_place_state = event.target.innerText
      this.task_tag4_placeActive = !this.task_tag4_placeActive
      this.task_tag4_placeSelected = true
    },
    // 任務設置之tag5: 產品名稱選擇事件
    selectProductItemEvent (id, event) {
      this.select_task_tag5_product_actionid = id
      this.select_task_tag5_product_state = event.target.innerText
      this.task_tag5_productActive = !this.task_tag5_productActive
      this.task_tag5_productSelected = true
    },
    // 日曆日期點選事件
    dayClickEvent (val) {
      // console.log(val)
      const date = new Date(val.date)
      var year = date.getFullYear()
      var month = String(date.getMonth() + 1).padStart(2, '0')
      var day = String(date.getDate()).padStart(2, '0')
      var dayString = year + '-' + month + '-' + day
      if (Object.keys(this.markedDates).includes(dayString)) {
        // console.log('It\'s in marked dates')
        // console.log(this.markedDates)
        this.clickedDate = dayString
        // 同時也將待辦任務、todo的初始日期選擇這個
        this.dateSelectForTaskAndTodo = dayString
        this.showActionSuggestions = true
        // console.log(dayString)
        // console.log('ch0.1', this.markedDates[dayString].length)
        // console.log('ch1.0', this.markedDates[dayString][0])
        // console.log('ch1.0.1', typeof this.markedDates[this.clickedDate])
        // console.log('ch1.1', this.markedDates[this.clickedDate].length)
      } else {
        this.clickedDate = ''
        this.showActionSuggestions = false
        // console.log(this.markedDates)
        // console.log('It\'s not in marked dates')
      }
    },
    // 取得所有栽培曆後端計算內容
    async getALlComputedResultForDynamicCalendar () {
      this.isLoading = true
      // 栽培表格顯示 (測試用)
      // 還要讓Chart繪製顯示
      await UserService.getALlComputedResultForDynamicCalendar(this.select_cropItem_state, this.selectedDay, this.select_weatherStations_state).then(
        res => {
          // console.log(res)
          const resTemp = res.data
          if (resTemp.is_err) {
            this.isLoading = false
            this.setNotificationDialog('無法取得栽培曆相關計算結果: ' + resTemp.err)
          } else {
            this.steps = resTemp.steps
            this.annotationXAxisData = resTemp.annotationXAxisData
            this.expectedData = resTemp.expectedData
            // console.log(this.expectedData)
            this.realData = resTemp.realData
            this.markedDates = resTemp.markedDates
            this.day_count = resTemp.day_count
            this.day_range = resTemp.day_range
            this.expected_day_count = resTemp.expected_day_count
            this.expected_day_range = resTemp.expected_day_range
            // 萬一有殘留就先做摧毀(有可能有消操作是沒有先初始就重新送)
            this.currentLineChartdata.destroy()
            // 動態抓取chart所在element的width並設置產生chart
            this.$nextTick(async function () {
              // 動態抓取element寬高的function
              const chartWidth1 = this.$refs.lineChartCanva.clientWidth
              // this.initG6(width, height, this.$store.state.current_graph_data)
              this.drawChart(chartWidth1)
            })
            this.showChart = true
            this.showCalendarTable = true
            this.showActionSuggestions = false
            this.isLoading = false
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('栽培曆相關計算請求，收到回應內容錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('向後端申請栽培曆相關計算資料失敗，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('向後端申請栽培曆相關計算資料，請求沒有成立')
          return false
        }
      })
    },
    // 圖表line chart繪製
    drawChart (chartWidth1) {
      var expectedData = this.expectedData
      var realData = this.realData
      var annotationXAxis = UserService.transformStatusDateDictToChartAnnotation(this.annotationXAxisData)
      // 取得expectedData中最大積溫，用來下面定義yaxis的max
      const maxObject = this.expectedData.reduce((maxObj, currentObj) => {
        const currentValue = currentObj[1]
        if (currentValue > maxObj.value) {
          return { date: currentObj[0], value: currentValue }
        }
        return maxObj
      }, { date: '', value: -Infinity })
      // console.log('max is ', maxObject.value)
      var options = {
        chart: {
          type: 'line',
          width: chartWidth1 * 0.9,
          height: chartWidth1 * 0.5,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          }
        },
        series: [
          {
            name: '理想積溫',
            data: expectedData
          },
          {
            name: '實際積溫',
            data: realData
          }
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              var date = new Date(value)
              var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
              return date.toLocaleDateString('zh-TW', options)
            }
          }
        },
        annotations: {
          xaxis: annotationXAxis
        },
        yaxis: {
          title: {
            text: '日積溫'
          },
          forceNiceScale: true,
          min: 0,
          max: maxObject.value * 1.2,
          // eslint-disable-next-line no-undef
          tickAmount: expectedData.length + 2// 使用 'data' 來自動計算刻度數量
        },
        markers: {
          // 兩條line的marked circle可不同大小，目前實際地設置較大
          size: [6, 4],
          shape: 'circle',
          colors: ['#0088ff', '#ffbb00'],
          strokeWidth: 0,
          strokeColors: '#fff'
        }
      }
      var chart = new ApexCharts(document.querySelector('#lineChart'), options)
      this.currentLineChartdata = chart
      chart.render()
    },
    // 20210821 上面func可以取得product list給選單，這邊則可以從選單選出一個product (此為一個點擊事件)
    async selectProductEvent (event) {
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      // Product code選擇欄位初始化
      this.select_state2 = '請選擇'
      // 透過選擇的product去獲取product code list
      await this.GetProductCodeList(this.select_state1)
      // 顯示下一個選單enable
      this.searchBar2 = true
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉 (record顯示也要清除)
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false
      // 圖譜顯示初始化清空
      this.current_graph_data = {}
    },
    async selectEvent2 (event) {
      this.clearActive = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉、record顯示也要清除
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false
      // await this.GetGraph(this.select_state2, 2, 3, this.select_state1)
      if (this.select_state2 === '所有農場批號') {
        await this.getGraph(this.select_state1, 2, 3, this.select_state1)
      } else {
        await this.getGraph(this.select_state2, 2, 3, this.select_state1)
      }
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      // const url = new URL(window.location)
      // url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      // url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      // window.history.pushState({}, '', url)
      // ---------------------------- graph產生前，先產生url會讓它顯示比較快，因為graph產生較花時間
      // graphInstance.read(this.current_graph)
    },
    async GetProductCodeList (product) {
      this.isLoading = true
      this.productCodeList = []
      this.productCodeList = this.productCodeList.concat('所有農場批號')
      // 取得知識圖譜產品Code選項
      await UserService.getProductCodeList(product).then(
        res => {
          const resTemp = res.data
          if (resTemp.is_err) {
            // 瀏覽器無法對end-user給予完整錯誤訊息內容，因此這個條件其實不太可能會成立
            this.isLoading = false
            this.setNotificationDialog('無法取得農場批號選單，可考慮重整理頁面，若無法排除將下列錯誤代號回報: ' + resTemp.err)
          } else {
            // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
            this.productCodeList = this.productCodeList.concat(resTemp.result)
            this.isLoading = false
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('農場批號選單選項資料內容錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('向後端申請農場批號選單選項資料失敗，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('向後端申請農場批號選單選項資料，請求沒有成立')
          return false
        }
      })
    },
    clear () {
      this.searchBar2 = false
      this.clearActive = false
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      this.select_state1 = '請選擇'
      this.current_graph_data = {}
      this.node_selected = false
      graphInstance.read(this.current_graph_data)
    },
    async getGraph (name, uLevel, dLevel, product) {
      this.isLoading = true
      await UserService.getGraph(name, uLevel, dLevel, product).then(
        res => {
          this.current_graph_data = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('依據選擇向後端取得圖譜資料內容錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('依據選擇向後端取得圖譜資料失敗，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('依據選擇向後端取得圖譜資料失敗，請求沒有成立')
          return false
        }
      })
      graphInstance.read(this.current_graph_data)
      this.isLoading = false
    },
    // 20220211 資訊頁面-節點選擇01
    // 取得node相關資訊，譬如name/content/content_tag (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/node-name-info
    async showDetail (nodeName) {
      this.detailActive = false
      this.detailTermActive = false
      this.node_info_current = {}
      this.node_term_current = {}
      await UserService.showDetail(nodeName).then(res => {
        this.detailActive = true
        this.node_info_current = res.data.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若請求失敗就跳出func 因為return強制要有內容，這邊只好以false為回傳，不過這在瀏覽器上還是會有400或跨域錯誤顯示
          this.setNotificationDialog('向後端取得節點相關資訊之內容錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('向後端取得節點相關資訊失敗，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('向後端取得節點相關資訊失敗，請求建立失敗')
          return false
        }
      })
      // 基本上能跑到下面這階段來說，那就表示上面request是成功的，我後端設計是IsErr一旦構成就會返回400，所以就會被上面catch攔截，這邊沒必要再做確認
      if (this.node_info_current.is_term) {
        this.detailTermActive = true
        // await this.getTerm(this.node_info_current.content_tag)
      } else {
        this.detailTermActive = false
      }
    },
    // 20220211 資訊頁面-節點選擇03
    // 取得各國翻譯詞彙，不用鎖螢幕，選擇的節點觸發，若是該節點為term則會有相關內容的獲取 (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/term-chinese
    async getTerm (chinese) {
      await UserService.getTerm(chinese).then(res => {
        this.node_term_current = res.data.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          this.setNotificationDialog('向後端取得圖譜節點的各國詞彙資料之內容錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('向後端取得圖譜節點的各國詞彙資料失敗，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('向後端取得圖譜節點的各國詞彙資料失敗，建立請求失敗')
          return false
        }
      })
    },
    // 20220211 資訊頁面-節點選擇02
    // 透過detail節點取得操作紀錄(若非detail節點結果是null)
    // POST https://www.ricehouse-backend.ntu.asia/service/product-detail-all-records
    async showRecords (product, detail) {
      this.recordActive = false
      await UserService.showRecords(product, detail).then(res => {
        if (res.data.result) {
          this.recordActive = true
          this.records = res.data.result
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          this.setNotificationDialog('向後端取得操作細節節點的相關的操作紀錄，內容出現錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('向後端取得操作細節節點的相關的操作紀錄，沒獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('向後端取得操作細節節點的相關的操作紀錄，請求建立失敗')
          return false
        }
      })
    },
    // 20220211 資訊頁面-節點操作
    // 圖譜的遮蔽、往前與往後，階層呈現變化會透過此請求來實踐，為了確保流暢性是不做鎖定畫面動作，會有較好的流暢性 (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-graph.ntu.asia/service/graph
    async MaskGetGraph (name, uLevel, dLevel, product) {
      await UserService.maskGetGraph(name, uLevel, dLevel, product).then(res => {
        this.mask_graph = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
        // console.log(this.current_graph)
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          this.setNotificationDialog('向後端取得遮蔽後圖譜部分資料，內容出現錯誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('向後端取得遮蔽後圖譜部分資料，沒有獲得回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('向後端取得遮蔽後圖譜部分資料，建立請求失敗')
          return false
        }
      })
    },
    async MaskGraph (name) {
      this.up_count = 0
      this.down_count = 0
      await this.MaskGetGraph(name, 0, 0, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    async UpLevel (name) {
      this.up_count += 1
      this.down_count += 1
      await this.MaskGetGraph(name, this.up_count, this.down_count, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    async DownLevel (name) {
      this.up_count -= 1
      this.down_count -= 1
      await this.MaskGetGraph(name, this.up_count, this.down_count, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    BackAllGraph () {
      graphInstance.read(this.current_graph_data)
    },
    initG6 (w, h, d) {
      // console.log('generating graph2')
      const data = d
      graphInstance = new G6.Graph({
        container: 'graph',
        fitView: true,
        width: w,
        height: h,
        defaultNode: {
          // fill: '#40a9ff',
          // stroke: '#096dd9'
        },
        defaultEdge: {
          type: 'line',
          style: {
            endArrow: {
              path: G6.Arrow.triangle(10, 10),
              fill: '#413960',
              lineWidth: 3
            },
            stroke: '#413960',
            lineWidth: 1
          }
        },
        layout: {
          type: 'force',
          preventOverlap: true,
          linkDistance: 550
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'] // 允许拖拽画布、放缩画布、拖拽节点
        }
      })
      // graph.read(data)
      // graph.on('node:dragstart', function (e) {
      //   graph.layout()
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:drag', function (e) {
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:dragend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })
      // function refreshDragedNodePosition (e) {
      //   const model = e.item.get('model')
      //   model.fx = e.x
      //   model.fy = e.y
      // }
      graphInstance.read(data)
      graphInstance.on('node:dragstart', function (e) {
        graphInstance.layout()
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:drag', function (e) {
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:dragend', function (e) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      graphInstance.on('node:click', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showRecords(this.select_state1, this.nodeName)
      })
      // 有時間的話會完成，選取他會highlight node並顯示node name
      // // 另外會data紀錄上一個選取的node，選取到另一個就要取消掉上個style
      // on這邊沒有開啟因為，這邊是檢視、檢查用，沒有要做圖譜操作
      graphInstance.on('node:touchend', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showRecords(this.select_state1, this.nodeName)
      })

      function refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      }
      // // console.log('generating graph3')
    }
  },
  async created () {
  },
  async mounted () {
    const currentDateTemp = new Date()
    // 轉換成2023-03-02形式
    const year = currentDateTemp.getFullYear()
    const month = String(currentDateTemp.getMonth() + 1).padStart(2, '0') // 使用 padStart 補齊兩個位數的月份
    const day = String(currentDateTemp.getDate()).padStart(2, '0') // 使用 padStart 補齊兩個位數的日期
    this.currentDate = `${year}-${month}-${day}`

    this.isLoading = true
    this.optionGetCount = 0
    // 呼叫取得作物選項與測站選項-----------------
    await UserService.getCropStationOptions().then(
      res => {
        const cropStationOptionData = res.data
        if (cropStationOptionData.is_err) {
          // 瀏覽器無法對end-user給予完整錯誤訊息內容，因此這個條件其實不太可能會成立
          this.isLoading = false
          // this.setNotificationDialog('無法取得作物測站選單，可考慮重整理頁面，若無法排除將下列錯誤代號回報: ' + cropStationOptionData.err)
        } else {
          this.cropItems = cropStationOptionData.result.crop_item_options
          this.weatherStations = cropStationOptionData.result.station_options
          this.isLoading = false
          this.optionGetCount++
          // this.setNotificationDialog('成功取得作物與測站選單')
        }
      }
    ).catch(err => {
      // console.log('Auth err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        // this.setNotificationDialog('動態知識栽培曆取得後端初始化選項資料內容錯誤')
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        // this.setNotificationDialog('動態知識栽培曆申請後端初始化選項資料，沒獲得回應')
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        // this.setNotificationDialog('動態知識栽培曆申請後端初始化選項資料，請求沒有成立')
        return false
      }
    }
    )
    // 取得知識圖譜產品選項-----------------
    await UserService.getProductList().then(
      res => {
        const resTemp = res.data
        if (resTemp.is_err) {
          // 瀏覽器無法對end-user給予完整錯誤訊息內容，因此這個條件其實不太可能會成立
          this.isLoading = false
        } else {
          // console.log(resTemp.productList)
          // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
          this.productList = resTemp.result
          this.tableProductList = resTemp.result
          this.optionGetCount++
        }
      }
    ).catch(err => {
      // console.log('Auth err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    // 取得履歷標籤-----------------
    await UserService.getAllActionInfoFromAppBackend().then(
      res => {
        const resTemp = res.data
        if (resTemp.is_err) {
          // 瀏覽器無法對end-user給予完整錯誤訊息內容，因此這個條件其實不太可能會成立
          this.isLoading = false
        } else {
          // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
          // console.log('action check point 1')
          // console.log(resTemp.result)
          // console.log(resTemp.result[1])
          // console.log(resTemp.result[1][0])
          this.task_tag1_product_code_actions = resTemp.result[1]
          this.task_tag2_operation_actions = resTemp.result[2]
          this.task_tag3_detail_actions = resTemp.result[3]
          this.task_tag4_place_actions = resTemp.result[4]
          this.task_tag5_product_actions = resTemp.result[5]
          this.optionGetCount++
        }
      }
    ).catch(err => {
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    // ----------------------------
    if (this.optionGetCount === 3) {
      this.setNotificationDialog('成功取得圖譜、栽培曆初始化選單資料與履歷標籤資料')
    } else if (this.optionGetCount === 2) {
      this.setNotificationDialog('圖譜、栽培曆初始化選單資料或履歷標籤資料取得失敗')
    } else if (this.optionGetCount === 1) {
      this.setNotificationDialog('圖譜、栽培曆初始化選單資料或履歷標籤資料取得失敗')
    } else {
      this.setNotificationDialog('圖譜、栽培曆初始化選單資料或履歷標籤資料取得失敗')
    }
    // var options = {}
    // var chart = new ApexCharts(document.querySelector('#lineChart'), options)
    // this.currentLineChartdata = chart
    // 動態抓取chart所在element的width並設置產生chart
    this.$nextTick(async function () {
      // 動態抓取element寬高的function
      const chartWidth1 = this.$refs.lineChartCanva.clientWidth
      // this.initG6(width, height, this.$store.state.current_graph_data)
      this.drawChart(chartWidth1)
    })
    // ---------------------------------------
    //
    this.$nextTick(function () {
      // 動態抓取element寬高的function
      // console.log('generating graph1')
      const width = this.$refs.canvas.clientWidth
      const height = this.$refs.canvas.clientHeight
      this.initG6(width, height, this.current_graph_data)
    })
    // ---------------------------------------
    this.isLoading = false
  },
  computed: {
    sortedSteps () {
      const sortedSteps = this.steps.slice().sort((a, b) => a.id - b.id)
      return sortedSteps
    },
    // 取得marked最小日期，讓日曆可以翻到最小日期所在頁面
    getMarkedSmallestDate () {
      const entries = Object.entries(this.markedDates)
      if (entries.length > 0) {
        const dates = Object.keys(this.markedDates)
        const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b))
        const minDateStr = sortedDates[0]
        const minDate = new Date(minDateStr)
        return minDate
      } else {
        const today = new Date()
        return today
      }
    },
    // 動態顯示栽培提醒廣播內容(default為設定檔提供設定的預期操作)
    dynamicSetNoteContent () {
      return this.setNoteContent
    },
    // 要記得點選該天也會是執行日，因此最終要加1
    betweenSelectedDayAndClickedDay () {
      const date1String = this.selectedDay
      const date2String = this.clickedDate

      const date1 = new Date(date1String)
      const date2 = new Date(date2String)

      // 將日期轉化為時間戳計算差異
      const timeDiff = Math.abs(date2.getTime() - date1.getTime())

      // 將時間差異轉換為天數， 要記得點選該天也會是執行日，因此最終要加1
      const daysBetween = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1
      return daysBetween
    },
    // 點選日記觸發顯示的栽培操作細節
    clickedDayActionsDetail () {
      if (Object.keys(this.markedDates).length === 0) {
        return []
      } else {
        return this.markedDates[this.clickedDate]
      }
      // console.log('check0.0 : ', this.clickedDate)
      // const a = this.markedDates[this.clickedDate]
      // console.log('check0.11: ', typeof a)
      // console.log('check0.5: ', this.markedDates['2023-06-10'][0].day_at)
      // console.log('check1.1: ', this.markedDates[this.clickedDate])
      // console.log('check1.2: ', this.markedDates[this.clickedDate].length)
      // const l = a.length
      // console.log(l)
      // console.log('check1.1: ', this.markedDates[this.clickedDate].length)
      // console.log('check1: ', this.markedDates[this.clickedDate][0].day_at)
    },
    // 標記劃記於日曆上
    markedFilterFormatDates () {
      if (typeof Object.keys(this.markedDates) !== 'undefined' && Object.keys(this.markedDates).length > 0) {
        return Object.keys(this.markedDates).filter(date => date !== this.currentDate).map(date => {
          const [year, month, day] = date.split('-')
          const filterFormattedDate = `${year}-${parseInt(month)}-${parseInt(day)}`
          return filterFormattedDate
        })
      } else {
        return []
      }
    },
    // 送出需要的選擇都已經選擇完畢(可以觸發顯示送出按鈕)
    calendarSetCompleted () {
      return (this.wasCropItemSelected && this.wasWeatherStationSelected && this.wasDateSelected)
    },
    // 用於task、todo設置的最小日，即當日(提醒跟待辦任務最小就是當日)
    minSetTaskAndToDoDay () {
      const today = new Date()
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Asia/Taipei'
      }
      const todayToStringFormat = today.toLocaleString('zh-TW', options).split('/').join('-')
      return todayToStringFormat
    },
    // 將日期設置成當日減兩天，為了最大日設置成當天減兩天天(因為實際上能抓到最穩的日期區間是到大前天，前天或當日氣象站有可能資料尚未更新)
    maxDate () {
      const today = new Date()
      // 將日期設置成當日減兩天，為了最大日設置成當天減兩天天(因為實際上能抓到最穩的日期區間是到大前天，前天或當日氣象站有可能資料尚未更新)
      today.setDate(today.getDate() - 2)
      const year = today.getFullYear()
      // 月份是0開始，因此要 +1 主換字串才會正常
      let month = today.getMonth() + 1
      let day = today.getDate()

      // 格式化日期字符串，确保月份和日期有两位数
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      const maxDate = new Date(`${year}-${month}-${day}`)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Asia/Taipei'
      }

      const maxDateToStringFormat = maxDate.toLocaleString('zh-TW', options).split('/').join('-')
      // console.log(maxDateToStringFormat)
      // console.log(minDate.toLocaleString('zh-TW', options).replace(/\./g, '-'))
      return maxDateToStringFormat
    },
    // 取得定值日天數差，當日減下selected day
    getDiffBetweenSelectedAndToday () {
      const selectedDay = new Date(this.selectedDay)
      console.log(selectedDay)
      const today = new Date()
      console.log(today)
      // 將日期轉換為時間戳記，以便計算差距
      const selectedTimestamp = selectedDay.getTime()
      const todayTimestamp = today.getTime()
      // 計算天數差距，採ceil向上取整，會包含今日
      const dayDifference = Math.ceil((todayTimestamp - selectedTimestamp) / (1000 * 60 * 60 * 24))
      console.log(dayDifference)
      return dayDifference
    },
    // ------------------------------------------------------
    contentsFilterAndSortedContentsByUnixDate () {
      // 取得相依的data，這樣只要這些內容產生變化就會觸發computed method
      const { contents, sortType, decrementOrIncrement } = this
      // 如果 contents 為 null，直接返回 null，不做任何排序
      if (!contents) {
        return null
      }
      // sortType為1採record ID排序、為2採unix date排序
      if (sortType === 1 && decrementOrIncrement === true) {
        return contents.sort(sortRecordsByRecordIDDecrement)
      } else if (sortType === 1 && decrementOrIncrement === false) {
        return contents.sort(sortRecordsByRecordIDIncrement)
      } else if (sortType === 2 && decrementOrIncrement === true) {
        return contents.sort(sortRecordsByUnixDateDecrement)
      } else {
        return contents.sort(sortRecordsByUnixDateIncrement)
      }
    }
  }
}
function sortRecordsByUnixDateDecrement (a, b) {
  const x = a.unix
  const y = b.unix
  return ((x > y) ? -1 : ((x < y) ? 1 : 0))
}
function sortRecordsByRecordIDDecrement (a, b) {
  const x = a.record_id
  const y = b.record_id
  return ((x > y) ? -1 : ((x < y) ? 1 : 0))
}
function sortRecordsByUnixDateIncrement (a, b) {
  const x = a.unix
  const y = b.unix
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
}
function sortRecordsByRecordIDIncrement (a, b) {
  const x = a.record_id
  const y = b.record_id
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
}
</script>

<style scoped>
.custom-task-dialog-h {
  height: 520px;
  width: 520px;
}

.custom-h-inside {
  height: 900px;
}
.custom-line-chart-h-inside {
  height: 520px;
}

.custom-record-table-inside {
  height: 500px;
}
.custom-table-h-inside {
  height: 345px;
}

/* scrollbar背景設置 */
.tableScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar滑動controller設置 */
.tableScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.tableScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar使用提示，使用拉動時候會變色 */
.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
/* table底色，更重要的是高度，限制畫面一次顯示的紀錄筆數，會影響scrollbar獲取的間距 */
.custom-table-inside {
  background-color: #8b8b8b;
  height: 500px;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
